import React from 'react'
import Loadable from 'react-loadable'

const CreateEducationEditor = Loadable({
  loader: () => import('./CreateEducationQuillEditor'),
  loading() {
    return 'Loading...'
  },
})
export default () => <CreateEducationEditor />
