import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CreateEducationEditor from 'components/ContentAdmin/EducationTab/EducationList/CreateEducationEditor'
// import PublishButton from '../components/posts/PublishButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    button: {
      marginTop: theme.spacing(3),
    },
    opinion: {
      width: theme.spacing(28),
    },
  })
)

export default () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.root}>
        <CreateEducationEditor />
        {/*<PublishButton />*/}
      </div>
    </Layout>
  )
}
